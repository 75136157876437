<template>
	<div>
		<AsiCardDetailEntry :icon="listEntryIcon" :label="mailTypeName">
			<template>
				<span v-if="mailTemplate.htmlTemplate === ''" class="font-italic">
					{{ $t('mailTemplate.terms.noTemplate') }}
				</span>
				<span v-else-if="mailTemplate.subject !== null">
					{{ mailTemplate.subject }}
				</span>
				<span v-else>
					-
				</span>
			</template>
			<template v-slot:actionButton v-if="!readonly">
				<AsiBtn v-if="mailTemplate.htmlTemplate === ''" @click="createDialog.open()" :icon="icons.add" prevent-icon-mode small/>
				<template v-else>
					<AsiBtn class="mr-2" @click="updateDialog.open()" :icon="icons.edit" prevent-icon-mode small/>
					<AsiBtn @click="requestDelete" :icon="icons.delete" color="error" prevent-icon-mode small/>
				</template>
			</template>
		</AsiCardDetailEntry>

		<MailTemplateCreateDialog v-if="createDialog.isLoaded" :subtitle="mailTypeName" :model="mailTemplate" :open="createDialog.isOpen"
		                          @cancel="createDialog.close()" @save="reload(); createDialog.close()">
			<template v-slot:infos>
				<p class="title">{{ $t('mailTemplate.terms.informationTitle') }}</p>
				<p class="body-1">{{ $t('mailTemplate.terms.informationCaption') }}</p>
				<MailTemplateInfoCard :title="$t('mailTemplate.terms.recipientInformations')" :icon="icons.mail" :infos="recipientInfos" :no-bottom-margin="!hasSpecificInfos"/>

				<slot name="specificInfos"/>
			</template>
		</MailTemplateCreateDialog>

		<MailTemplateUpdateDialog v-if="updateDialog.isLoaded" :subtitle="mailTypeName" :model="mailTemplate" :open="updateDialog.isOpen"
		                          @cancel="updateDialog.close()" @save="reload(); updateDialog.close()">
			<template v-slot:infos>
				<p class="title">{{ $t('mailTemplate.terms.informationTitle') }}</p>
				<p class="body-1">{{ $t('mailTemplate.terms.informationCaption') }}</p>
				<MailTemplateInfoCard :title="$t('mailTemplate.terms.recipientInformations')" :icon="icons.mail" :infos="recipientInfos" :no-bottom-margin="!hasSpecificInfos"/>

				<slot name="specificInfos"/>
			</template>
		</MailTemplateUpdateDialog>

		<AsiConfirmDialog ref="confirm"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCardDetailEntry from "../common/AsiCardDetailEntry.vue";
	import AsiBtn from "../common/AsiBtn.vue";
	import Icon from "../../plugins/icons";
	import {MailType} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";
	import DialogHandler from "@/components/common/DialogHandler";
	import MailTemplateCreateDialog from "@/components/mail-template/MailTemplateCreateDialog.vue";
	import MailTemplateInfoCard from "@/components/mail-template/MailTemplateInfoCard.vue";
	import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";
	import MailTemplateUpdateDialog from "@/components/mail-template/MailTemplateUpdateDialog.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import AsiConfirmDialogDefinition from "@/helpers/AsiConfirmDialogDefinition";
	import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";

	@Component({
		components: {
			AsiConfirmDialog,
			MailTemplateUpdateDialog,
			MailTemplateInfoCard, MailTemplateCreateDialog, AsiBtn, AsiCardDetailEntry
		}
	})
	export default class MailTemplateDetailEntry extends Vue {

		@Prop({type: Object, required: true})
		public mailTemplate!: IMailTemplateAdminListEntry;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private createDialog: DialogHandler = new DialogHandler();
		private updateDialog: DialogHandler = new DialogHandler();

		private get mailTypeName(): string | null {
			return EnumHelper.textFromValue(MailType, this.mailTemplate.mailType, true);
		}

		private get listEntryIcon(): string {
			return this.mailTemplate.htmlTemplate !== "" ? this.icons.fileCheck : this.icons.close;
		}

		private get recipientInfos(): MailTemplateInfo[] {
			return [
				new MailTemplateInfo('{salutation}', 'mailTemplate.placeholders.base.salutation'),
				new MailTemplateInfo('{firstname}', 'mailTemplate.placeholders.base.firstname'),
				new MailTemplateInfo('{lastname}', 'mailTemplate.placeholders.base.lastname'),
				new MailTemplateInfo('{fullSalutation}', 'mailTemplate.placeholders.base.fullSalutation'),
			];
		}

		private get hasSpecificInfos(): boolean {
			return !!this.$slots.specificInfos;
		}

		private requestDelete(): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				this.deleteTemplate();
			});
		}

		@Emit('save')
		public reload(): void {
			return;
		}

		@Emit('delete')
		public deleteTemplate(): string {
			return this.mailTemplate.id;
		}

	}
</script>

<style lang="scss" scoped>

</style>
