<template>
	<section>
		<AsiPageTitle :icon="icons.customer" :title="name" :loading="loading" :show-menu-actions="canMergeCustomer || canCreateUser">
			<template v-slot:menu-actions>
				<AsiMenuItem v-if="canMergeCustomer" :icon="icons.merge" :label="$t('customer.terms.merge')" :disabled="customer === null"
				             @click="mergeDialog.open()"/>
				<AsiMenuItem v-if="canCreateUser" :icon="icons.user" :label="$t('user.terms.create')" :disabled="customer === null"
				             @click="userCreateDialog.open()"/>
			</template>
			<template v-slot:subtitle>
				<CustomerTypeChip v-if="customer !== null" :customer="customer"/>
			</template>
		</AsiPageTitle>
		<AsiTabs v-model="activeTab">
			<v-tab key="details">
				{{ $t('customer.singular') }}
				<v-icon>{{ icons.customer }}</v-icon>
			</v-tab>
			<v-tab-item key="details">
				<CustomerTabDetails :customer="customer" @change="customerChanged"/>
			</v-tab-item>

			<v-tab key="addresses">
				{{ $t('address.plural') }}
				<v-icon>{{ icons.addresses }}</v-icon>
			</v-tab>
			<v-tab-item key="addresses">
				<v-divider/>
				<CustomerTabAddresses v-if="customer !== null" :customer="customer"
				                      :readonly="!rMasterData" @customerChanged="customerChanged"/>
			</v-tab-item>

			<v-tab key="users">
				{{ $t('user.plural') }}
				<v-icon>{{ icons.users }}</v-icon>
			</v-tab>
			<v-tab-item key="users">
				<v-divider/>
				<UserList :customer-id="id" memory-key="admin-customer-users" ref="userList"/>
			</v-tab-item>

			<v-tab key="carts">
				{{ $t('cart.plural') }}
				<v-icon>{{ icons.carts }}</v-icon>
			</v-tab>
			<v-tab-item key="carts">
				<v-divider/>
				<CartList :customer-id="id" memory-key="admin-customer-carts"/>
			</v-tab-item>

			<v-tab key="orders">
				{{ $t('order.plural') }}
				<v-icon>{{ icons.orders }}</v-icon>
			</v-tab>
			<v-tab-item key="orders">
				<v-divider/>
				<OrderList :customer-id="id" memory-key="admin-customer-orders"/>
			</v-tab-item>
		</AsiTabs>

		<CustomerMergeDialog v-if="canMergeCustomer && customer !== null && mergeDialog.isLoaded" :open="mergeDialog.isOpen"
		                     :customer="customer" @cancel="mergeDialog.close()" @save="merged"/>
		<UserCreateDialog v-if="canCreateUser && customer !== null && userCreateDialog.isLoaded" :customer-id="customer.id"
		                  :open="userCreateDialog.isOpen" @save="userCreated" @cancel="userCreateDialog.close()"/>
	</section>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
	import UserList from "@/components/user/admin/UserList.vue";
	import AsiTabs from "@/components/common/AsiTabs";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
	import CustomerTabDetails from "@/components/customer/admin/CustomerTabDetails.vue";
	import CustomerTabAddresses from "@/components/customer/admin/CustomerTabAddresses.vue";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import CartList from "@/components/cart/admin/CartList.vue";
	import OrderList from "@/components/order/admin/OrderList.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
	import CustomerMergeDialog from "@/components/customer/admin/CustomerMergeDialog.vue";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import UserCreateDialog from "@/components/user/UserCreateDialog.vue";

	@Component({
		components: {
			UserCreateDialog,
			CustomerMergeDialog,
			AsiMenuItem,
			OrderList,
			CartList,
			CustomerTabAddresses,
			CustomerTabDetails, CustomerTypeChip, AsiTabs, UserList, AsiContentContainer, AsiPageTitle
		}
	})
	export default class Customer extends mixins(RightChecks) {

		@Prop({type: String, required: true})
		public id!: string;

		public icons = Icon;
		public loading: boolean = false;
		public customer: ICustomerAdmin | null = null;
		public mergeDialog: DialogHandler = new DialogHandler();
		public userCreateDialog: DialogHandler = new DialogHandler();

		public get name(): string | null {
			return this.customer?.name ?? null;
		}

		public get canMergeCustomer(): boolean {
			return this.rAdmin;
		}

		public get canCreateUser(): boolean {
			const ownCustomerId = this.$store.getters['user/model']?.customer.id;
			const currentCustomerId = this.customer?.id;
			return this.rAdmin
				|| (this.rCustomerManager && ownCustomerId !== undefined && currentCustomerId !== undefined && ownCustomerId === currentCustomerId);
		}

		public get activeTab(): number {
			return this.$store.getters['ui/activeTabAdmin']('customer');
		}

		public set activeTab(index: number) {
			this.$store.commit('ui/activeTabAdmin', {key: 'customer', activeTab: index});
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadCustomer();
		}

		public customerChanged(): void {
			if (this.id === this.$store.getters['user/modelCustomer']?.id) {
				this.$store.dispatch('user/loadLoggedInUserData');
			}
			this.loadCustomer();
		}

		private loadCustomer(): void {
			this.loading = true;
			this.$customerServiceAdmin.customer(this.id)
				.then(model => this.customer = model)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}

		private merged(targetId: string): void {
			this.mergeDialog.close();
			this.$router.push(CustomerHelper.detailRouteAdmin(targetId));
		}

		private userCreated(): void {
			this.userCreateDialog.close();

			let list = this.$refs.userList;
			if (list !== undefined) {
				(this.$refs.userList as UserList).reload();
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
