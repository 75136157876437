<template>
	<section>
		<AsiPageTitle :icon="icons.order" :title="title" :loading="loading">
			<template v-slot:subtitle>
				<AsiBreadcrumbs :entries="path" hide-icons link-entries/>
			</template>
		</AsiPageTitle>

		<OrderTabDetails :order="order" @change="loadOrder"/>

	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import {IOrderAdmin} from "@/models/order/OrderAdminModels";
	import Snackbar from "@/helpers/Snackbar";
	import VueI18n from "vue-i18n";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import PathEntry from "@/models/PathEntry";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import OrderTabDetails from "@/components/order/admin/OrderTabDetails.vue";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {OrderTabDetails, AsiBreadcrumbs, AsiPageTitle}
	})
	export default class Order extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		public icons = Icon;
		public loading: boolean = false;
		public order: IOrderAdmin | null = null;

		public get title(): string | null {
			const parts: (string | TranslateResult)[] = [this.$t('order.singular')];
			if (this.order !== null) {
				parts.push(this.order.orderNumber.toString());
			}
			return parts.join(' ');
		}

		public get path(): PathEntry[] {
			return this.order === null || this.order.customer === null
				? []
				: [CustomerHelper.pathEntry(this.order.customer, true)];
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadOrder();
		}

		public loadOrder(): void {
			if (this.id) {
				this.loading = true;
				this.$orderServiceAdmin.order(this.id)
					.then(model => this.order = model)
					.catch(() => Snackbar.loadingError())
					.finally(() => this.loading = false);
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
