<template>
	<section>
		<AsiPageTitle :icon="icon" :title="nameTranslated" :loading="loading || loadingAttributes" :avatar-bg-color="color">
			<template v-slot:subtitle>
				<AsiBreadcrumbs v-if="path.length > 0" :entries="path" hide-icons link-entries/>
			</template>
			<template v-slot:fixed-actions>
				<ItemAddToCart v-if="item !== null && item.itemType === itemType.linkItem" :item="item"/>
			</template>
		</AsiPageTitle>

		<AsiTabs v-model="activeTab">
			<v-tab key="details">
				{{ $t('item.singular') }}
				<v-icon>{{ icons.item }}</v-icon>
			</v-tab>
			<v-tab-item key="details">
				<ItemTabDetails :item="item" @change="loadItem"/>
			</v-tab-item>

			<v-tab key="attributes">
				{{ $t('item.terms.attributes') }}
				<v-icon>{{ icons.attributes }}</v-icon>
			</v-tab>
			<v-tab-item key="attributes">
				<ItemTabAttributes :item="item" :loading.sync="loadingAttributes" @change="loadItem"/>
			</v-tab-item>

			<v-tab key="attachments">
				{{ $t('item.terms.downloads') }}
				<v-icon>{{ icons.file }}</v-icon>
			</v-tab>
			<v-tab-item key="attachments">
				<ItemTabAttachments :item="item" :readonly="!rAdmin" @change="loadItem"/>
			</v-tab-item>

			<v-tab key="images">
				{{ $t('ui.terms.images') }}
				<v-icon>{{ icons.fileImage }}</v-icon>
			</v-tab>
			<v-tab-item key="images">
				<ItemTabImages :item="item" :readonly="!rAdmin" @change="loadItem"/>
			</v-tab-item>

			<v-tab key="related-items">
				{{ $t('item.terms.relatedItems') }}
				<v-icon>{{ icons.relatedItems }}</v-icon>
			</v-tab>
			<v-tab-item key="related-items">
				<ItemTabRelatedItems :item-id="id"/>
			</v-tab-item>
		</AsiTabs>
	</section>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import AsiTabs from "@/components/common/AsiTabs";
	import {RawLocation} from "vue-router";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import ItemHelper from "@/models/item/ItemHelper";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import PathEntry from "@/models/PathEntry";
	import ItemTabDetails from "@/components/item/admin/ItemTabDetails.vue";
	import ItemTabImages from "@/components/item/admin/ItemTabImages.vue";
	import ItemTabAttributes from "@/components/item/admin/ItemTabAttributes.vue";
	import ItemTabAttachments from "@/components/item/admin/ItemTabAttachments.vue";
	import {ItemType} from "@/helpers/constants";
	import ItemAddToCart from "@/components/item/shop/ItemAddToCart.vue";
	import ItemList from "@/components/item/admin/ItemList.vue";
	import ItemTabRelatedItems from "@/components/item/admin/ItemTabRelatedItems.vue";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";

	@Component({
		components: {
			ItemTabRelatedItems,
			ItemList,
			ItemAddToCart,
			ItemTabAttachments,
			ItemTabAttributes,
			ItemTabImages, ItemTabDetails, AsiBreadcrumbs, AsiTabs, AsiPageTitle, AsiContentContainer
		}
	})
	export default class Item extends mixins(RightChecks) {

		@Prop({type: String, required: true})
		public id!: string;

		private icons = Icon;
		private itemType = ItemType;

		private loading: boolean = false;
		private loadingAttributes: boolean = false;
		private item: IItemAdmin | null = null;

		private get color(): string | null {
			return this.item === null ? null : ItemHelper.colorHierarchical(this.item);
		}

		private get icon(): string {
			return this.item?.itemType === ItemType.linkItem ? this.icons.link : this.icons.item;
		}

		private get nameTranslated(): string | null {
			return this.item === null ? null : TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
		}

		private get categoryNameTranslated(): string | null {
			return this.item === null ? null : TranslatedValueHelper.get(this.item.category.name, this.$i18n.locale);
		}

		private get categoryRoute(): RawLocation | null {
			return this.item === null ? null : CategoryHelper.detailRouteAdmin(this.item.category.id);
		}

		private get path(): PathEntry[] {
			return this.item === null ? [] : ItemHelper.path(this.item, true);
		}

		private get activeTab(): number {
			return this.$store.getters['ui/activeTabAdmin']('item');
		}

		private set activeTab(index: number) {
			this.$store.commit('ui/activeTabAdmin', {key: 'item', activeTab: index});
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadItem();
		}

		private loadItem(): void {
			this.loading = true;
			this.$itemServiceAdmin.item(this.id)
				.then(model => this.item = model)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
