<template>
	<ShopView>
		<h1 class="mb-6">{{ $t('customer.terms.myCustomer') }}</h1>
		<CustomerHeader :customer="customer" :readonly="readonlyCustomer" :loading="loading" @change="customerChanged"/>

		<AsiCard unwrapped no-bottom-margin class="overflow-hidden">
			<AsiTabs centered v-model="activeTab">
				<v-tab key="addresses">
					{{ $t('address.plural') }}
					<v-icon>{{ icons.addresses }}</v-icon>
				</v-tab>
				<v-tab-item key="addresses">
					<CustomerTabAddresses v-if="customer !== null" :customer="customer" :readonly="readonlyAddresses"
					                      @customerChanged="loadCustomer"/>
				</v-tab-item>

				<v-tab key="users" v-if="isBusinessAccount">
					{{ $t('user.plural') }}
					<v-icon>{{ icons.users }}</v-icon>
				</v-tab>
				<v-tab-item key="users" v-if="isBusinessAccount">
					<CustomerTabUsers v-if="customer !== null" :customer-id="customer.id" :readonly="readonlyUsers"/>
				</v-tab-item>
			</AsiTabs>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import {ICustomerShop} from "@/models/customer/CustomerShopModels";
	import Snackbar from "@/helpers/Snackbar";
	import ShopView from "@/components/layout/ShopView.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiTabs from "@/components/common/AsiTabs";
	import Icon from "@/plugins/icons";
	import CustomerHeader from "@/components/customer/shop/CustomerHeader.vue";
	import CustomerTabAddresses from "@/components/customer/shop/CustomerTabAddresses.vue";
	import CustomerTabUsers from "@/components/customer/shop/CustomerTabUsers.vue";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import {CustomerType} from "@/helpers/constants";

	@Component({
		components: {
			CustomerTabUsers,
			CustomerTabAddresses,
			CustomerHeader,
			AsiTabs, AsiCard, ShopView
		}
	})
	export default class Customer extends mixins(RightChecks) {

		private static readonly ACTIVE_TAB_KEY = 'customerDetail';

		@Prop({type: String, default: null})
		public id!: string | null;

		private icons = Icon;
		private customer: ICustomerShop | null = null;
		private loading: boolean = false;

		public created(): void {
			this.loadCustomer();
		}

		private get activeTab(): number {
			return this.$store.getters['ui/activeTabShop'](Customer.ACTIVE_TAB_KEY);
		}

		private set activeTab(index: number) {
			this.$store.commit('ui/activeTabShop', {key: Customer.ACTIVE_TAB_KEY, activeTab: index});
		}

		private get isManagerOfCustomer(): boolean {
			if (this.customer === null) return false;
			return this.rCustomerManager && this.$store.getters['user/modelCustomer']?.id === this.customer.id;
		}

		private get isOwnCustomer(): boolean {
			const ownId = this.$store.getters['user/modelCustomer']?.id ?? null;
			if (ownId === null) return false;
			return this.id === ownId || this.customer?.id === ownId;
		}

		private get isBusinessAccount(): boolean {
			return this.customer?.type === CustomerType.business;
		}

		private get readonlyCustomer(): boolean {
			return !this.rMasterData && !this.isManagerOfCustomer;
		}

		private get readonlyAddresses(): boolean {
			return !this.rMasterData && !this.isManagerOfCustomer;
		}

		private get readonlyUsers(): boolean {
			return !this.rAdmin && !this.isManagerOfCustomer;
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadCustomer();
		}

		private customerChanged(): void {
			this.loadCustomer();
			if (this.isOwnCustomer) {
				this.$store.dispatch('user/loadLoggedInUserData');
			}
		}

		private loadCustomer(): void {
			const idFinal = this.id ?? this.$store.getters['user/modelCustomer']?.id ?? null;
			if (idFinal === null) return;

			this.loading = true;
			this.$customerServiceShop.customer(idFinal)
				.then(model => this.customer = model)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
