<template>
	<AsiSkeletonCards v-if="category === null" :num="1" :columns="2" loading/>

	<AsiContentContainer v-else :columns="2">
		<div>
			<CategoryCardBasicData :category="category" @change="change" :readonly="!rAdmin"/>
		</div>
		<div>
			<CategoryCardDescription :category="category" @change="change" :readonly="!rAdmin"/>
			<CategoryCardCanonical :category="category" :readonly="!rAdmin"/>
		</div>
		<div>
			<CategoryCardMeta :category="category" @change="change" :readonly="!rMetaData"/>
		</div>
		<div>
			<CategoryCardState :category="category" @change="change" :readonly="!rAdmin"/>
		</div>
	</AsiContentContainer>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import CategoryCardMeta from "@/components/category/admin/CategoryCardMeta.vue";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import CategoryCardBasicData from "@/components/category/admin/CategoryCardBasicData.vue";
	import CategoryCardState from "@/components/category/admin/CategoryCardState.vue";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import CategoryCardDescription from "@/components/category/admin/CategoryCardDescription.vue";
	import CategoryCardCanonical from "@/components/category/admin/CategoryCardCanonical.vue";

	@Component({
		components: {
			CategoryCardCanonical,
			CategoryCardDescription,
			CategoryCardState, CategoryCardBasicData, CategoryCardMeta, AsiSkeletonCards, AsiContentContainer
		}
	})
	export default class CategoryTabDetails extends mixins(RightChecks) {

		@Prop({required: true})
		public category!: ICategoryAdmin | null;

		private get isImported(): boolean | null {
			if (this.category) {
				return CategoryHelper.isImported(this.category);
			}
			return null;
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
