<template>
	<div>
		<div class="grey lighten-4">
			<DashboardGrowthFilter @update="updateFilter"/>
		</div>
		<AsiContentContainer no-bottom-margin>
			<v-row>
				<v-col lg="6" md="12">
					<AsiCardDetail v-if="userGrowth !== null" :title="$t('ui.terms.userGrowth')" :icon="icons.chartLineUp" highlighted
					               :loading="loadingUserGrowth" unwrapped>
						<GrowthLineChart v-if="userGrowthChartData.length > 0" :chart-data="userGrowthChartData"/>

						<AsiCardDetailEntry :label="$t('ui.terms.totalAmount')" :icon="icons.user" :value="userGrowth.total"/>
					</AsiCardDetail>
				</v-col>
				<v-col lg="6" md="12">
					<AsiCardDetail v-if="customerGrowth !== null" :title="$t('ui.terms.customerGrowth')" :icon="icons.chartLineUp" highlighted
					               :loading="loadingCustomerGrowth" unwrapped>
						<GrowthLineChart v-if="customerGrowthChartData.length > 0" :chart-data="customerGrowthChartData"/>

						<AsiCardDetailEntry :label="$t('ui.terms.totalAmount')" :icon="icons.customer" :value="customerGrowth.total"/>
					</AsiCardDetail>
				</v-col>
			</v-row>
			<v-row>
				<v-col lg="6" md="12">
					<AsiCardDetail v-if="orderGrowth !== null" :title="$t('ui.terms.orderGrowth')" :icon="icons.chartLineUp" highlighted
					               :loading="loadingOrderGrowth" unwrapped>
						<GrowthLineChart v-if="orderGrowthChartData.length > 0" :chart-data="orderGrowthChartData"/>

						<AsiCardDetailEntry :label="$t('ui.terms.totalAmount')" :icon="icons.order" :value="orderGrowth.total"/>
					</AsiCardDetail>
				</v-col>
				<v-col lg="6" md="12">
					<AsiCardDetail v-if="orderSalesGrowth !== null" :title="$t('ui.terms.orderSalesGrowth')" :icon="icons.chartLineUp" highlighted
					               :loading="loadingOrderGrowth" unwrapped>
						<GrowthLineChart v-if="orderSalesGrowthChartData.length > 0" :chart-data="orderSalesGrowthChartData"/>

						<AsiCardDetailEntry :label="$t('ui.terms.totalAmount')" :icon="icons.prices" :value="$n(orderSalesGrowth.totalSalesVolume.amount, {key: 'currencyDisplay', currency: orderSalesGrowth.totalSalesVolume.currency.currencyCode})"/>
					</AsiCardDetail>
				</v-col>
			</v-row>
		</AsiContentContainer>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Watch} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import GrowthLineChart from "@/components/dashboard/admin/GrowthLineChart.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icons from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import DashboardGrowthFilter from "@/components/dashboard/admin/DashboardGrowthFilter.vue";
	import {IGrowthChart, IGrowthChartChartData} from "@/models/GrowthChartModel";
	import {IPrice} from "@/models/price/PriceModels";
	import {IOrderAdminSalesGrowthChart} from "@/models/order/OrderAdminModels";

	type CounterFunction<T> = (item: T) => number;

	@Component({
		components: {
			DashboardGrowthFilter,
			AsiDatePickerCombined,
			AsiBtn, AsiCardDetailEntry, AsiContentContainer, GrowthLineChart, AsiCardDetail
		}
	})
	export default class DashboardGrowth extends Vue {
		private icons = Icons;

		private loadingUserGrowth: boolean = false;
		private userGrowth: IGrowthChart | null = null;
		private userGrowthChartData: IGrowthChartChartData[] = [];

		private loadingCustomerGrowth: boolean = false;
		private customerGrowth: IGrowthChart | null = null;
		private customerGrowthChartData: IGrowthChartChartData[] = [];

		private loadingOrderGrowth: boolean = false;
		private orderGrowth: IGrowthChart | null = null;
		private orderSalesGrowth: IOrderAdminSalesGrowthChart | null = null;
		private orderGrowthChartData: IGrowthChartChartData[] = [];
		private orderSalesGrowthChartData: IGrowthChartChartData[] = [];

		private dateRangeFilter: string[] = [];

		private loadUserGrowth(): void {
			const startDate = new Date(this.dateRangeFilter[0]);
			const endDate = new Date(this.dateRangeFilter[1]);

			this.loadingUserGrowth = true;
			this.$userServiceAdmin.userGrowth(startDate.toISOString(), endDate.toISOString())
				.then(data => this.userGrowth = data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingUserGrowth = false);
		}

		private loadCustomerGrowth(): void {
			const startDate = new Date(this.dateRangeFilter[0]);
			const endDate = new Date(this.dateRangeFilter[1]);

			this.loadingCustomerGrowth = true;
			this.$customerServiceAdmin.customerGrowth(startDate.toISOString(), endDate.toISOString())
				.then(data => this.customerGrowth = data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingCustomerGrowth = false);
		}

		private loadOrderGrowth(): void {
			const startDate = new Date(this.dateRangeFilter[0]);
			const endDate = new Date(this.dateRangeFilter[1]);

			this.loadingOrderGrowth = true;
			this.$orderServiceAdmin.orderGrowth(startDate.toISOString(), endDate.toISOString())
				.then(data => {
					this.orderGrowth = {total: data.total, growth: data.growth};
					this.orderSalesGrowth = {total: data.total, growth: data.growth, totalSalesVolume: data.totalSalesVolume, prices: data.prices};
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingOrderGrowth = false);
		}

		private updateFilter(range: string[]): void {
			this.dateRangeFilter = range;
		}

		private prepareGenericChartData(dates: Date[]): IGrowthChartChartData[] {
			let newestDate = new Date(this.dateRangeFilter[0]);
			let oldestDate = new Date(this.dateRangeFilter[1]);

			if (this.monthsBetween(newestDate, oldestDate) > 1) {
				// monthly count
				return this.countByMonth(dates, dates, () => 1, newestDate, oldestDate);
			} else {
				// daily count
				return this.countByDay(dates, dates, () => 1, newestDate, oldestDate);
			}
		}

		private prepareSalesChartData(dates: Date[], prices: IPrice[]): IGrowthChartChartData[] {
			let newestDate = new Date(this.dateRangeFilter[0]);
			let oldestDate = new Date(this.dateRangeFilter[1]);

			if (this.monthsBetween(newestDate, oldestDate) > 1) {
				// monthly count
				return this.countByMonth(dates, prices, (price) => price.amount, newestDate, oldestDate);
			} else {
				// daily count
				return this.countByDay(dates, prices, (price) => price.amount, newestDate, oldestDate);
			}
		}

		private monthsBetween(date1: Date, date2: Date): number {
			if (date1 > date2) {
				let temp = date1;
				date1 = date2;
				date2 = temp;
			}

			let yearsDiff = date2.getFullYear() - date1.getFullYear();
			let monthsDiff = date2.getMonth() - date1.getMonth();

			return yearsDiff * 12 + monthsDiff;
		}


		private countByMonth<T>(dates: Date[], items: T[], counter: CounterFunction<T>, startDate: Date, endDate: Date): IGrowthChartChartData[] {
			const monthCounts: Record<string, number> = {};

			items
				.map(i => ({
					date: new Date(dates[items.indexOf(i)]),
					item: i
				}))
				.forEach(i => {
					const year = i.date.getFullYear();
					const month = String(i.date.getMonth() + 1).padStart(2, '0');
					const label = `${year}-${month}`;

					if (monthCounts[label]) {
						monthCounts[label] += counter(i.item);
					} else {
						monthCounts[label] = counter(i.item);
					}
				});

			const result: IGrowthChartChartData[] = [];
			let currentDate = new Date(startDate.getTime());

			while (currentDate <= endDate) {
				const year = currentDate.getFullYear();
				const month = String(currentDate.getMonth() + 1).padStart(2, '0');
				const label = `${year}-${month}`;

				result.push({
					label,
					count: monthCounts[label] || 0,
				});

				currentDate.setMonth(currentDate.getMonth() + 1);
			}

			return result;
		}

		private countByDay<T>(dates: Date[], items: T[], counter: CounterFunction<T>, startDate: Date, endDate: Date): IGrowthChartChartData[] {
			const dayCounts: Record<string, number> = {};

			items
				.map(i => ({
					date: new Date(dates[items.indexOf(i)]),
					item: i
				}))
				.forEach(i => {
					const day = String(i.date.getDate()).padStart(2, '0');
					const month = String(i.date.getMonth() + 1).padStart(2, '0');
					const label = `${day}.${month}`;

					if (dayCounts[label]) {
						dayCounts[label] += counter(i.item);
					} else {
						dayCounts[label] = counter(i.item);
					}
				});

			const result: IGrowthChartChartData[] = [];
			let currentDate = new Date(startDate.getTime());

			while (currentDate <= endDate) {
				const day = String(currentDate.getDate()).padStart(2, '0');
				const month = String(currentDate.getMonth() + 1).padStart(2, '0');
				const label = `${day}.${month}`;

				result.push({
					label,
					count: dayCounts[label] || 0,
				});

				currentDate.setDate(currentDate.getDate() + 1);
			}

			return result;
		}

		@Watch('dateRangeFilter')
		private onDateRangeFilterChanged(): void {
			this.loadUserGrowth();
			this.loadCustomerGrowth();
			this.loadOrderGrowth();
		}

		@Watch('userGrowth')
		private onUserGrowthChanged(value: IGrowthChart): void {
			this.userGrowthChartData = this.prepareGenericChartData(value.growth);
		}

		@Watch('customerGrowth')
		private onCustomerGrowthChanged(value: IGrowthChart): void {
			this.customerGrowthChartData = this.prepareGenericChartData(value.growth);
		}

		@Watch('orderGrowth')
		private onOrderGrowthChanged(value: IGrowthChart): void {
			this.orderGrowthChartData = this.prepareGenericChartData(value.growth);
		}

		@Watch('orderSalesGrowth')
		private onOrderSalesGrowthChanged(value: IOrderAdminSalesGrowthChart): void {
			this.orderSalesGrowthChartData = this.prepareSalesChartData(value.growth, value.prices);
		}
	}
</script>

<style lang="scss" scoped>

</style>
