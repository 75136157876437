<template>
	<section>
		<template v-if="!readonly">
			<v-divider/>
			<div class="pa-6 text-center grey lighten-5">
				<AsiBtn :icon="icons.file" @click="uploadDialog.open()" color="secondary">
					{{ $t('ui.terms.uploadAttachments') }}
				</AsiBtn>
			</div>
			<v-divider/>
		</template>
		<AsiContentContainer no-bottom-margin>
			<AttachmentList v-if="item !== null" :item="item" :showActions="true" :readonly="readonly"
			                @deleted="change" @changed="change"/>
		</AsiContentContainer>

		<AttachmentUploadDialog v-if="!readonly && uploadDialog.isLoaded" :open="uploadDialog.isOpen"
		                        :model="item" :service="$itemServiceAdmin"
		                        @cancel="uploadDialogCancelled"/>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import ItemAttachments from "@/components/item/shop/ItemAttachments.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AttachmentUploadDialog from "@/components/attachment/AttachmentUploadDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import AttachmentList from "@/components/attachment/AttachmentList.vue";

	@Component({
		components: {AttachmentList, AttachmentUploadDialog, AsiBtn, ItemAttachments, AsiContentContainer}
	})
	export default class ItemTabAttachments extends Vue {

		@Prop({required: true})
		public item!: IItemAdmin | null;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private uploadDialog: DialogHandler = new DialogHandler();

		private uploadDialogCancelled(numSuccessful: number): void {
			this.uploadDialog.close();
			if (numSuccessful > 0) this.change();
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
