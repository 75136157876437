import { render, staticRenderFns } from "./SettingsCardMailTemplatesGeneral.vue?vue&type=template&id=99f1bff8&scoped=true"
import script from "./SettingsCardMailTemplatesGeneral.vue?vue&type=script&lang=ts"
export * from "./SettingsCardMailTemplatesGeneral.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f1bff8",
  null
  
)

export default component.exports