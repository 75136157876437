import PathEntry from "@/models/PathEntry";
import Icon from "@/plugins/icons";
import i18n from "@/plugins/i18n";
import {RawLocation} from "vue-router";
import {AttachmentMediaSize, CategoryState, Routes} from "@/helpers/constants";
import {
	ICategoryAdmin,
	ICategoryAdminListEntry,
	ICategoryAdminPathEntry,
	ICategoryAdminSimple
} from "@/models/category/CategoryAdminModels";
import {
	ICategoryShopListEntry,
	ICategoryShopPathEntry,
	ICategoryShopSimple
} from "@/models/category/CategoryShopModels";
import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
import AttachmentHelper from "@/models/attachment/AttachmentHelper";
import store from "@/store";
import StringHelper from "@/helpers/StringHelper";
import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";

export default class CategoryHelper {

	public static detailRoute(canonical: string): RawLocation {
		return {
			name: Routes.category,
			params: {
				canonical: canonical,
			},
		};
	}

	public static detailRouteAdmin(id: string): RawLocation {
		return {
			name: Routes.adminCategory,
			params: {
				id: id,
			},
		};
	}

	public static avatarUrl(model: ICategoryAdminSimple | ICategoryShopSimple, size: AttachmentMediaSize): string | null {
		const catWithAvatar = this.categoryPath(model).reverse().find(c => c.avatarAttachmentId !== null);
		if (catWithAvatar === undefined) return null;

		const avatar = catWithAvatar.galleryAttachments.find(a => a.id === catWithAvatar.avatarAttachmentId);
		return avatar === undefined ? null : AttachmentHelper.getImageUrl(avatar, size);
	}

	public static colorHierarchical(model: ICategoryAdminSimple | ICategoryShopSimple): string | null {
		let color = this.categoryPath(model).reverse().find(c => c.color !== null)?.color ?? null;
		if (color !== null) return color;
		color = getComputedStyle(document.documentElement).getPropertyValue('--v-primary-base').trim();
		return StringHelper.isEmpty(color) ? null : color;
	}

	public static sort(a: ICategoryShopListEntry | ICategoryAdminListEntry, b: ICategoryShopListEntry | ICategoryAdminListEntry) {
		const nameA = TranslatedValueHelper.get(a.name, i18n.locale);
		const nameB = TranslatedValueHelper.get(b.name, i18n.locale);
		if (nameA === null && nameB === null) return 0;
		if (nameA !== null && nameB === null) return -1;
		if (nameA === null && nameB !== null) return 1;
		if (nameA !== null && nameB !== null) return nameA.localeCompare(nameB);
		return 0;
	}

	public static pathIcon(): string {
		return Icon.category;
	}

	public static pathLabel(model: ICategoryAdminPathEntry | ICategoryShopPathEntry): string {
		return TranslatedValueHelper.get(model.name, i18n.locale, true) ?? '?';
	}

	public static pathEntry(model: ICategoryAdminPathEntry | ICategoryShopPathEntry, adminRoute: boolean = false): PathEntry {
		return new PathEntry(this.pathIcon(), this.pathLabel(model),
			adminRoute
				? this.detailRouteAdmin(model.id)
				: this.detailRoute(this.currentCanonical(model.canonical)));
	}

	public static path(model: ICategoryAdminSimple | ICategoryShopSimple, adminRoutes: boolean = false): PathEntry[] {
		return model.path.map(c => CategoryHelper.pathEntry(c, adminRoutes));
	}

	public static isImported(model: ICategoryAdmin | null): boolean {
		return model === null || model.externalId !== null;
	}

	public static stateColor(model: ICategoryAdminSimple | ICategoryShopSimple): string {
		switch (model.state) {
			case CategoryState.active:
				return 'success';
			case CategoryState.archived:
			default:
				return 'error';
		}
	}

	public static stateIcon(model: ICategoryAdminSimple | ICategoryShopSimple): string {
		switch (model.state) {
			case CategoryState.active:
				return Icon.unarchive;
			case CategoryState.archived:
			default:
				return Icon.archive;
		}
	}

	public static currentCanonical(canonical: ITranslatedValue, locale: string | null = null): string {
		return TranslatedValueHelper.get(canonical, locale ?? i18n.locale) ?? '';
	}

	private static categoryPath(model: ICategoryAdminSimple | ICategoryShopSimple, includeSelf: boolean = true): (ICategoryAdminListEntry | ICategoryShopListEntry)[] {
		return model.path
			.map(c => store.getters['category/categoryById'](c.id))
			.filter(c => c !== null)
			.concat(includeSelf ? [model] : []);
	}

}
