<template>
	<AsiCardDetail :title="$t('mailTemplate.order')" no-bottom-margin unwrapped>
		<MailTemplateDetailEntry v-for="template in templates" :key="template.id"
		                         :mail-template="template" :readonly="readonly"
		                         @save="save" @delete="emitDelete">
			<template v-slot:specificInfos>
				<MailTemplateInfoCard :title="$t('mailTemplate.terms.orderInformations')"
				                      :infos="orderInfos(template.mailType)" :icon="icons.order" no-bottom-margin/>
			</template>
		</MailTemplateDetailEntry>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import MailTemplateDetailEntry from "@/components/mail-template/MailTemplateDetailEntry.vue";
	import MailTemplateInfoCard from "@/components/mail-template/MailTemplateInfoCard.vue";
	import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";
	import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";
	import {MailType} from "@/helpers/constants";

	@Component({
		components: {MailTemplateInfoCard, MailTemplateDetailEntry, AsiBtn, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class SettingsCardMailTemplatesOrder extends Vue {

		@Prop({type: Array, required: true})
		public templates!: IMailTemplateAdminListEntry[];

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		public icons = Icon;

		public orderInfos(type: MailType): MailTemplateInfo[] {
			const placeHolders = [];

			if (type === MailType.mailTypeOrderConfirmed) {
				placeHolders.push(new MailTemplateInfo('{orderNumber}', 'mailTemplate.placeholders.orderConfirmation.orderNumber'));
				placeHolders.push(new MailTemplateInfo('{link}', 'mailTemplate.placeholders.orderConfirmation.link'));
			}

			if (type === MailType.mailTypeOrderShipped) {
				placeHolders.push(new MailTemplateInfo('{orderNumber}', 'mailTemplate.placeholders.orderShipped.orderNumber'));
				placeHolders.push(new MailTemplateInfo('{orderCreatedAt}', 'mailTemplate.placeholders.orderShipped.orderCreatedAt'));
				placeHolders.push(new MailTemplateInfo('{orderShippingAddressCity}', 'mailTemplate.placeholders.orderShipped.orderShippingAddressCity'));
				placeHolders.push(new MailTemplateInfo('{link}', 'mailTemplate.placeholders.orderShipped.link'));
			}

			if (type === MailType.mailTypeOrderReadyForPickup) {
				placeHolders.push(new MailTemplateInfo('{orderNumber}', 'mailTemplate.placeholders.orderReadyForPickup.orderNumber'));
				placeHolders.push(new MailTemplateInfo('{orderCreatedAt}', 'mailTemplate.placeholders.orderReadyForPickup.orderCreatedAt'));
				placeHolders.push(new MailTemplateInfo('{link}', 'mailTemplate.placeholders.orderReadyForPickup.link'));
			}

			return placeHolders;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('delete')
		public emitDelete(id: string): string {
			return id;
		}

	}
</script>

<style lang="scss" scoped>

</style>
