<template>
	<section>
		<div class="px-6 py-6 d-flex flex-row justify-space-between align-center">
			<div class="d-flex flex-column align-start">
				<div class="mt-2 text-h4 font-weight-bold text-uppercase">
					{{ $t('ui.terms.shopBackend') }}
				</div>
				<LogoHerzogDichtungen v-if="$isHerzogShop" style="max-height: 1rem"/>
				<LogoMkDichtungen v-if="$isMkShop" style="max-height: 1rem"/>
			</div>
			<AsiBtn color="primary" :icon="icons.frontend" large @click="goToShopHome">
				{{ $t('ui.terms.openShopFrontend') }}
			</AsiBtn>
		</div>
		<v-divider class="my-0"/>

		<DashboardGrowth />
		<v-divider/>
		<AsiContentContainer :columns="2">
			<div>
				<AsiCard :title="$t('order.terms.newest')" :icon="icons.orders" highlighted
				         :loading="loadingOrders" unwrapped>
					<template v-slot:headerActions>
						<AsiBtn :icon="icons.reload" :loading="loadingOrders" color="white" @click="loadNewestOrders"/>
					</template>
					<v-list class="pa-0">
						<template v-for="(order, i) in orders">
							<v-list-item :key="`${order.id}-entry`" @click="showOrder(order)">
								<v-list-item-avatar>
									<AsiAvatar :image-url="order.customer !== null ? customerAvatarUrl(order.customer) : null"
									           :icon="icons.order" :size="32"/>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
									<span v-if="order.customer === null">
										{{ fullname(order.shippingAddress) + ' Nr. ' + order.orderNumber }}
									</span>
										<span v-else>
										{{ order.customer.name }}
									</span>
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ $d(new Date(order.createdAt), 'long') }}
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-icon small>{{ icons.view }}</v-icon>
								</v-list-item-action>
							</v-list-item>
							<v-divider v-if="i < orders.length - 1" :key="`${order.id}-divider`"/>
						</template>
					</v-list>
				</AsiCard>
			</div>
			<div>
				<AsiCard :title="$t('customer.terms.newest')" :icon="icons.customers" highlighted
				         :loading="loadingCustomers" unwrapped>
					<template v-slot:headerActions>
						<AsiBtn :icon="icons.reload" :loading="loadingCustomers" color="white" @click="loadNewestCustomers"/>
					</template>
					<v-list class="pa-0">
						<template v-for="(customer, i) in customers">
							<v-list-item :key="`${customer.id}-entry`" @click="showCustomer(customer)">
								<v-list-item-avatar>
									<AsiAvatar :image-url="customerAvatarUrl(customer)"
									           :icon="icons.customer" :size="32"/>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										{{ customer.name }}
									</v-list-item-title>
									<v-list-item-subtitle v-if="customer.createdAt">
										{{ $d(new Date(customer.createdAt), 'long') }}
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-icon small>{{ icons.view }}</v-icon>
								</v-list-item-action>
							</v-list-item>
							<v-divider v-if="i < orders.length - 1" :key="`${customer.id}-divider`"/>
						</template>
					</v-list>
				</AsiCard>
			</div>
		</AsiContentContainer>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {AttachmentMediaSize, Routes} from "@/helpers/constants";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import LogoHerzogDichtungen from "@/components/layout/LogoHerzogDichtungen.vue";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {IOrderAdminListEntry} from "@/models/order/OrderAdminModels";
	import Snackbar from "@/helpers/Snackbar";
	import OrderHelper from "@/helpers/OrderHelper";
	import {
		ICustomerAdminListEntry,
		ICustomerAdminSimple
	} from "@/models/customer/CustomerAdminModels";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import LogoMkDichtungen from "@/components/layout/LogoMkDichtungen.vue";
	import {IAddressFields} from "@/models/address/AddressModels";
	import GrowthLineChart from "@/components/dashboard/admin/GrowthLineChart.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import user from "@/store/modules/user";
	import DashboardGrowth from "@/components/dashboard/admin/DashboardGrowth.vue";

	@Component({
		computed: {
			user() {
				return user;
			}
		},
		components: {
			DashboardGrowth,
			AsiCardDetailEntry,
			AsiCardDetail,
			GrowthLineChart,
			LogoMkDichtungen, AsiAvatar, AsiCard, LogoHerzogDichtungen, AsiContentContainer, AsiBtn}
	})
	export default class Home extends Vue {

		private icons = Icon;

		private loadingOrders: boolean = false;
		private orders: IOrderAdminListEntry[] = [];
		private loadingCustomers: boolean = false;
		private customers: ICustomerAdminListEntry[] = [];

		public created(): void {
			this.loadNewestOrders();
			this.loadNewestCustomers();
		}

		private goToShopHome(): void {
			this.$router.push({name: Routes.home});
		}

		private customerAvatarUrl(customer: ICustomerAdminSimple): string | null {
			return CustomerHelper.avatarUrl(customer, AttachmentMediaSize.s);
		}

		private showOrder(order: IOrderAdminListEntry): void {
			this.$router.push(OrderHelper.detailRouteAdmin(order.id));
		}

		private showCustomer(customer: ICustomerAdminListEntry): void {
			this.$router.push(CustomerHelper.detailRouteAdmin(customer.id));
		}

		private loadNewestOrders(): void {
			const options = new AsiListTableOptions();
			options.itemsPerPage = 10;
			options.page = 1;
			options.sortBy = ['createdAt'];
			options.sortDesc = [true];

			this.loadingOrders = true;
			this.$orderServiceAdmin.orders(null, options)
				.then(data => this.orders = data.data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingOrders = false);
		}

		private loadNewestCustomers(): void {
			const options = new AsiListTableOptions();
			options.itemsPerPage = 10;
			options.page = 1;
			options.sortBy = ['createdAt'];
			options.sortDesc = [true];

			this.loadingCustomers = true;
			this.$customerServiceAdmin.customers(null, options)
				.then(data => this.customers = data.data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingCustomers = false);
		}

		private fullname(customer: IAddressFields): string {
			return customer.firstname + ' ' + customer.lastname;
		}

	}
</script>

<style lang="scss" scoped>

</style>
