<template>
	<nav>
		<template v-for="(group, i) in navGroups">
			<v-list :key="`${i}-group`" dense flat>
				<v-list-item-group color="primary">
					<template v-for="entry in group.entries">
						<v-list-item v-if="typeof(entry.target) !== 'function'" :key="entry.label" :exact="entry.exact" :to="entry.target" link>
							<v-list-item-icon v-if="entry.badgeValue === null || entry.badgeValue() === null">
								<v-icon size="24">{{ entry.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-icon v-else>
								<v-badge :color="entry.badgeColor()" left overlap top>
									<template v-slot:badge>
										<small>{{ entry.badgeValue() }}</small>
									</template>
									<v-icon size="24">{{ entry.icon }}</v-icon>
								</v-badge>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ entry.label }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-else :key="entry.label" :exact="entry.exact" link @click="entry.target()">
							<v-list-item-icon v-if="entry.badgeValue === null || entry.badgeValue() === null">
								<v-icon size="24">{{ entry.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-icon v-else>
								<v-badge :color="entry.badgeColor()" left overlap top>
									<template v-slot:badge>
										<small>{{ entry.badgeValue() }}</small>
									</template>
									<v-icon size="24">{{ entry.icon }}</v-icon>
								</v-badge>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ entry.label }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-list-item-group>
			</v-list>
			<v-divider v-if="i < navGroups.length - 1" :key="`${i}-separator`" class="ma-0"/>
		</template>
	</nav>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import NavGroup from "@/helpers/NavGroup";
	import NavEntry from "@/helpers/NavEntry";
	import Icon from "@/plugins/icons";
	import {Routes} from "@/helpers/constants";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";

	@Component
	export default class PrimaryNavigation extends mixins(RightChecks) {

		private navGroups: NavGroup[] = [];

		public created(): void {
			this.navGroups = [
				this.createGlobalGroup(),
				this.createSalesGroup(),
				this.createMasterDataGroup(),
				this.createCustomerDataGroup(),
				this.createSettingsGroup(),
			].filter(g => g.entries.length > 0);
		}

		private createGlobalGroup(): NavGroup {
			const group = new NavGroup();
			group.add(new NavEntry(this.$t('pageTitles.admin.home'), Icon.home, {name: Routes.adminHome}, true));
			return group;
		}

		private createSalesGroup(): NavGroup {
			const group = new NavGroup();
			group.add(new NavEntry(this.$t('order.plural'), Icon.orders, {name: Routes.adminOrders}, false));
			group.add(new NavEntry(this.$t('cart.plural'), Icon.carts, {name: Routes.adminCarts}, false));
			if (this.rMasterData || this.rMetaData) {
				group.add(new NavEntry(this.$t('shoppingList.plural'), Icon.shoppingList, {name: Routes.adminShoppingLists}));
			}
			return group;
		}

		private createMasterDataGroup(): NavGroup {
			const group = new NavGroup();
			group.add(new NavEntry(this.$t('category.plural'), Icon.categories, {name: Routes.adminCategories}, false));
			group.add(new NavEntry(this.$t('item.plural'), Icon.items, {name: Routes.adminItems}, false));
			return group;
		}

		private createCustomerDataGroup(): NavGroup {
			const group = new NavGroup();
			group.add(new NavEntry(this.$t('customer.plural'), Icon.customers, {name: Routes.adminCustomers}, false));
			group.add(new NavEntry(this.$t('user.plural'), Icon.users, {name: Routes.adminUsers}, false));
			return group;
		}

		private createSettingsGroup(): NavGroup {
			const group = new NavGroup();
			group.add(new NavEntry(this.$t('pageTitles.admin.settings'), Icon.settings, {name: Routes.adminSettings}, true));
			return group;
		}

	}
</script>

<style lang="scss" scoped>

</style>
