<template>
	<AsiCard :title="title === null ? $t('right.plural') : title" :icon="icons.rights" :loading="loadingInternal" unwrapped>
		<v-list class="pa-0">
			<template v-for="role in roleOptions">
				<v-list-item :key="`${role.value}-entry`">
					<v-list-item-icon>
						<v-icon size="24" :color="hasRole(role.value) ? 'success' : 'error'" style="margin-top: 2px;">
							{{ hasRole(role.value) ? icons.yes : icons.no }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							<span v-if="!hideTypeHints" class="font-weight-bold">
								{{ $t('right.role.singular') }}:
							</span>
							{{ role.text }}
						</v-list-item-title>
						<v-list-item-subtitle v-if="hint(role.value) !== null">
							{{ hint(role.value) }}
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action v-if="!readonly">
						<v-tooltip top>
							<template v-slot:activator="{on, attrs}">
								<span v-on="on" v-bind="attrs">
									<AsiBtn :disabled="loading || isOwnUser || hasRoleImplicit(role.value)" prevent-icon-mode
									        :icon="hasRole(role.value) ? icons.no : icons.yes"
									        :color="hasRole(role.value) ? 'error' : 'success'"
									        @click="toggleRole(role.value)"/>
								</span>
							</template>

							<span v-if="!hasRoleImplicit(role.value)">
								{{
									$t(hasRole(role.value) ? 'right.terms.revoke' : 'right.terms.assign', {
										right: role.text,
									})
								}}
							</span>
							<span v-else>
								{{ $t('right.terms.implicit') }}
							</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
				<v-divider :key="`${role.value}-divider`"/>
			</template>

			<v-divider v-if="roleOptions.length > 0 && permissionOptions.length > 0"/>

			<template v-for="permission in permissionOptions">
				<v-list-item :key="`${permission.value}-entry`">
					<v-list-item-icon>
						<v-icon size="24" :color="hasPermission(permission.value) ? 'success' : 'error'" style="margin-top: 2px;">
							{{ hasPermission(permission.value) ? icons.yes : icons.no }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							<span v-if="!hideTypeHints" class="font-weight-bold">
								{{ $t('right.permission.singular') }}:
							</span>
							{{ permission.text }}
						</v-list-item-title>
						<v-list-item-subtitle v-if="hint(permission.value) !== null">
							{{ hint(permission.value) }}
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action v-if="!readonly">
						<v-tooltip top>
							<template v-slot:activator="{on, attrs}">
								<span v-on="on" v-bind="attrs">
									<AsiBtn :disabled="loading || isOwnUser || hasPermissionImplicit(permission.value)" prevent-icon-mode
									        :icon="hasPermission(permission.value) ? icons.no : icons.yes"
									        :color="hasPermission(permission.value) ? 'error' : 'success'"
									        @click="togglePermission(permission.value)"/>
								</span>
							</template>

							<span v-if="!hasPermissionImplicit(permission.value)">
								{{
									$t(hasPermission(permission.value) ? 'right.terms.revoke' : 'right.terms.assign', {
										right: permission.text,
									})
								}}
							</span>
							<span v-else>
								{{ $t('right.terms.implicit') }}
							</span>
						</v-tooltip>
					</v-list-item-action>
				</v-list-item>
				<v-divider :key="`${permission.value}-divider`"/>
			</template>
		</v-list>
	</AsiCard>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {Permissions, Roles} from "@/helpers/constants";
	import Snackbar from "@/helpers/Snackbar";
	import AsiCard from "@/components/common/AsiCard.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import VueI18n from "vue-i18n";
	import IUserServiceAdmin from "@/services/definition/IUserServiceAdmin";
	import IUserServiceShop from "@/services/definition/IUserServiceShop";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiBtn, AsiCard}
	})
	export default class UserCardRights extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		@Prop({type: Object, required: true})
		public service!: IUserServiceAdmin | IUserServiceShop;

		@Prop({type: Array, default: () => []})
		public roles!: Roles[];

		@Prop({type: Array, default: () => []})
		public assignedRoles!: Roles[];

		@Prop({type: Array, default: () => []})
		public permissions!: Permissions[];

		@Prop({type: Array, default: () => []})
		public assignedPermissions!: Permissions[];

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public hideTypeHints!: boolean;

		public icons = Icon;
		private loadingInternal: boolean = false;

		public get isOwnUser(): boolean {
			return this.$store.getters['user/userId'] === this.id;
		}

		public get roleOptions(): { value: string | number, text: string }[] {
			return EnumHelper
				.toSelectItems(Roles, true)
				.filter(r => this.roles.includes(r.value as Roles));
		}

		public get permissionOptions(): { value: string | number, text: string }[] {
			return EnumHelper
				.toSelectItems(Permissions, true)
				.filter(p => this.permissions.includes(p.value as Permissions));
		}

		@Watch('loading', {immediate: true})
		private onLoadingChanged(value: boolean): void {
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		private onLoadingInternalChanged(value: boolean): void {
			if (this.loading !== value) this.$emit('update:loading', value);
		}

		@Emit('change')
		public change(): void {
			return;
		}

		public hasRole(role: Roles): boolean {
			return this.hasRoleExplicit(role) || this.hasRoleImplicit(role);
		}

		public hasRoleExplicit(role: Roles): boolean {
			return this.assignedRoles.includes(role);
		}

		public hasRoleImplicit(role: Roles): boolean {
			return role !== Roles.roleAdmin && this.hasRoleExplicit(Roles.roleAdmin);
		}

		private toggleRole(role: Roles): void {
			if (!this.roles.includes(role)) {
				return;
			}

			this.loadingInternal = true;
			(this.hasRole(role) ? this.service.revokeRole(this.id, role) : this.service.assignRole(this.id, role))
				.then(() => this.change())
				.catch(() => Snackbar.updateError())
				.finally(() => {
					this.loadingInternal = false;
					this.change();
				});
		}

		private hasPermission(permission: Permissions): boolean {
			return this.hasPermissionExplicit(permission) || this.hasPermissionImplicit(permission);
		}

		private hasPermissionExplicit(permission: Permissions): boolean {
			return this.assignedPermissions.includes(permission);
		}

		private hasPermissionImplicit(permission: Permissions): boolean {
			switch (permission) {
				case Permissions.rightCustomerManager:
					return false;
				case Permissions.rightBackendUser:
					return [
						Roles.roleAdmin,
						Roles.roleMasterData,
						Roles.roleMetaData,
						Roles.roleFinance,
					].some(r => this.hasRoleExplicit(r));
				default:
					return this.hasRoleExplicit(Roles.roleAdmin);
			}
		}

		private togglePermission(permission: Permissions): void {
			if (!this.permissions.includes(permission)) {
				return;
			}

			this.loadingInternal = true;

			(this.hasPermission(permission) ? this.service.revokePermission(this.id, permission) : this.service.assignPermission(this.id, permission))
				.then(() => this.change())
				.catch(() => Snackbar.updateError())
				.finally(() => {
					this.loadingInternal = false;
					this.change();
				});
		}

		public hint(value: Roles | Permissions): string | TranslateResult | null {
			switch (value) {
				case Roles.roleAdmin:
					return this.$t('right.role.hints.admin');
				case Roles.roleMasterData:
					return this.$t('right.role.hints.masterData');
				case Roles.roleMetaData:
					return this.$t('right.role.hints.metaData');
				case Roles.roleFinance:
					return this.$t('right.role.hints.finance');
				case Permissions.rightBackendUser:
					return this.$t('right.permission.hints.backendUser');
				case Permissions.rightCustomerManager:
					return this.$t('right.permission.hints.customerManager');
				default:
					return null;
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
